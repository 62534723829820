
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/ai/components/vueTypedJs";
import { Toast, Dialog } from "vant";
// import { UserService, GenService } from "src/services";
import { tools } from "src/utils";

@Component({
  components: {
    VueTypedJs,
  },
})
export default class OptionalAddition extends Vue {
  /**
   * 是否展开
   * @private
   * @returns boolean
   */
  private isExpand: boolean = false;

  /**
   * state
   * @private
   */
  private state: any = { showPwd: false, isRememberPassword: false };

  /**
   * 定时器
   * @private
   */
  private timerInterval: any = null;

  /**
   * formData
   * @private
   */
  private formData: any = {
    module: 1,
    checked: false,
  };

  /**
   * 账号登录
   * @protected
   * @returns boolean
   */
  private accountModel: any = {
    loginType: 1,
    loginKey: "",
    loginVal: "",
    module: 2,
  };

  /**
   * 手机验证码登录
   * @protected
   * @returns boolean
   */
  private accountModelTel: any = {
    loginType: 2,
    loginKey: "",
    loginVal: "",
    module: 2,
  };

  /**
   * 倒计时
   * @protected
   * @returns boolean
   */
  private timerValue: any = 300;

  /**
   * 获取验证码
   * @protected
   * @returns
   */
  private onGetCode(): void {
    if (this.timerValue == 300) {
      let regex = /^1[3456789]\d{9}$/;
      if (this.accountModelTel.loginKey.trim() === "") {
        Toast("请输入您的手机号码！");
        return;
      } else if (!regex.test(this.accountModelTel.loginKey)) {
        Toast("请输入正确的手机号码！");
        return;
      }
      this.smsSend();
      this.timerValue--;
      this.timerInterval = setInterval(this.timer, 1000);
    }
  }

  /**
   * 请求状态
   * @protected
   * @returns boolean
   */
  private loading: boolean = false;

  /**
   * 短信发送
   * @private
   * @return void
   */
  private async smsSend(): Promise<void> {
    // try
    // {
    //     let json = {smsType: 1, phone: this.accountModelTel.loginKey};
    //     await GenService.instance.sendSms(json);
    //     Toast("验证码已发送，请注意查收！");
    // }
    // catch(err)
    // {
    //     Toast(err);
    //     this.timerValue = 300;
    //     clearInterval(this.timerInterval);
    // }
  }

  /**
   * 验证码-计时器
   * @private
   * @returns
   */
  private timer(): void {
    if (this.timerValue > 1) {
      this.timerValue--;
    } else {
      this.timerValue = 300;
      clearInterval(this.timerInterval);
    }
  }
  /**
   * 组件创建钩子
   * @protected
   * @returns
   */
  protected mounted() {
    let loginDataStr = localStorage.getItem("loginData");
    if (loginDataStr) {
      this.state.isRememberPassword = true;
      let data = JSON.parse(loginDataStr);
      this.accountModel.loginType = 1;
      this.accountModel.loginKey = data && data.loginKey;
      this.accountModel.loginVal = data && data.loginVal;
      this.accountModel.module = 2;
    }
  }

  /**
   * 登录
   * @private
   * @return void
   */
  private async onHandleSubmit(): Promise<void> {
    // try
    // {
    //     if(!this.formData.checked)
    //     {
    //         Toast("请先勾选服务协议");
    //         return;
    //     }
    //     if(this.loading){
    //         return;
    //     };
    //     // 帐号登入情况
    //     if(this.formData.module == 1)
    //     {
    //         if(this.state.isRememberPassword)
    //         {
    //             let loginData = JSON.stringify(this.accountModel);
    //             localStorage.setItem("loginData", loginData)
    //         }
    //         else
    //         {
    //             localStorage.removeItem("loginData")
    //         }
    //     }
    //     this.loading = true;
    //     let json = this.formData.module == 1 ? this.accountModel : this.accountModelTel;
    //     // 用户登录
    //     let {content: result} = await UserService.instance.login(Object.assign({}, {mustBePerfect: true}, json));
    //     if(result.data)
    //     {
    //         this.$store.dispatch("setUserInfo", result.data);
    //         Toast("登录成功！");
    //         this.showLoginPopup = false;
    //         this.$emit("onSubmit", json);
    //     }
    //     this.loading = false;
    // }
    // catch(err)
    // {
    //     this.loading = false;
    //     Toast(err)
    // }
  }

  /**
   * 跳转隐私协议
   * @private
   */
  private onPrivacy() {
    this.$router.push({ name: "privacy" });
  }

  /**
   * 跳转用户协议
   * @private
   */
  private onAgreement() {
    this.$router.push({ name: "agreement" });
  }

  /**
   * 文字输入完成
   * @private
   */
  private onCompleteTxt() {
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  // 文字持续输入
  private onKeepTyping() {
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  /**
   * 输入框移入事件
   * @private
   * @returns void
   */
  private onMessageInput() {
    window.scrollTo(0, document.body.scrollHeight);
  }
}
