
import { Component, Vue } from "vue-property-decorator";
import { PackageService } from "src/services";
import { Toast } from "vant";
import { tools } from "src/utils";
import globalConfig from "src/config/index";

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component
export default class PackageDetail extends Vue {
  /**
   * 套餐信息
   * @private
   * @returns any
   */
  private packageInfo: any = {};

  /**
   * 搜索条件
   * @private
   * @returns any
   */
  private filter: any = {
    employerMemberId: null,
    packageId: null, // 套餐id
    hospitalId: 0, // 医院Id
    cityName: null, // 城市名称
    longitude: null, // 经度
    latitude: null, // 纬度
  };

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next): void {
    let appid = localStorage.getItem("appid");
    if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname) {
      location.assign(`/${appid}${to.fullPath}`);
    } else {
      next();
    }
  }

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 医院/机构id
   * @private
   * @returns string
   */
  private get hospitalId(): string {
    return (this.$route.query && (this.$route.query.hospitalId as string)) || "";
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get selectUserId(): any {
    let selectUserStorage = localStorage.getItem("selectUser");

    if (selectUserStorage) {
      return JSON.parse(selectUserStorage).employerMemberId || this.userInfo.userId;
    }

    return this.userInfo.userId;
  }

  /**
   * 套餐id
   * @private
   * @returns string
   */
  private get packageId(): string {
    return (this.$route.query && (this.$route.query.id as string)) || "";
  }

  /**
   * 当前位置
   * @private
   * @returns any
   */
  private get location(): any {
    return this.$store.getters.location || {};
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    return this.$store.getters.userInfo || {};
  }

  /**
   * 组件创建
   * @private
   * @returns void
   */
  private created(): void {
    if (this.hospitalId) {
      this.filter.hospitalId = this.hospitalId;
    }

    this.filter.packageId = this.packageId;
    this.filter.employerMemberId = this.selectUserId;

    this.getPackageDetail(this.filter);
  }

  /**
   * 获取套餐详情
   * @private
   * @returns void
   */
  private async getPackageDetail(data: any): Promise<void> {
    try {
      let { content: result } = await PackageService.instance.getPhysicalPackageOnlyInfo(data);

      if (result.data) {
        this.packageInfo = result.data.packageInfo;
      }
    } catch (err) {
      Toast(err);
    }
  }
}
