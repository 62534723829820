
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { ReportService } from "src/services";

@Component
export default class AiReportList extends Vue {
  /**
   * employerMemberId
   * @private
   * @returns string
   */
  private get employerMemberId(): string {
    return this.$route.query && this.$route.query.employerMemberId + "";
  }

  /**
   * 列表数据
   * @protected
   * @returns Array<any>
   */
  protected dataList: Array<any> = [];

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  protected created(): void {
    this.getList();
  }

  /**
   * 获取列表
   */
  protected async getList(): Promise<any> {
    try {
      let { content: result } = await ReportService.instance.getPhysicalReportList({ employerMemberId: this.employerMemberId });
      if (result.data) {
        this.dataList = result.data.reportGroups;
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 获取详情
   */
  protected onDetail(item: any): any {
    this.$router.push({ name: "reportAppointmentDetail", query: { id: item.id + "", isAi: "true" } });
  }
}
