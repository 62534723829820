
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/ai/components/vueTypedJs";
import eventBus from "src/views/ai/utils/bus";
import { Fun } from "src/views/ai/utils/common";

@Component({
  components: {
    VueTypedJs,
  },
})
export default class TitleButton extends Vue {
  /**
   * 输入值
   * @private
   * @returns string
   */
  private inputValue: string = "";

  /**
   * 监听inputValue
   * @protected
   * @param {any} - to 当前路由实例。
   * @returns {void}
   */
  @Watch("inputValue", { deep: true, immediate: true })
  protected changeInputValue(newValue: any, oldValue: any): void {
    if (this.dataSource.type != 2) {
      return;
    }
    if (newValue.length > 6) {
      this.inputValue = newValue.substring(0, 6);
    } else if (newValue.length == 6) {
      this.onToDo(this.dataSource.button);
    }
  }

  /**
   * 数据
   * @private
   * @returns string
   * @param type 1 输入框，2验证码
   */
  @Prop({ type: Object, default: { title: "", type: 2, button: { callFunc: "", callFuncData: {} } } })
  private dataSource: any;

  // 文字持续输入
  private onKeepTyping() {
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  /**
   * 文字输入完成
   * @private
   */
  private onCompleteTxt() {
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  /**
   * 执行方法
   * @private
   */
  private onToDo({ callFunc, callFuncData }) {
    callFuncData.code = this.inputValue;
    this.$emit("toDo", { callFunc, callFuncData });
  }

  // 钩子
  protected mounted() {
    setTimeout(() => {
      document.getElementById("box").onclick = () => {
        this.focus();
      };
    }, 100);
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private focus(): void {
    (this.$refs.pwd as any).focus();
  }
}
