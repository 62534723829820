
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/ai/components/vueTypedJs";
import { Fun } from "src/views/ai/utils/common";

@Component({
  components: {
    VueTypedJs,
  },
})
export default class TitleButton extends Vue {
  /**
   * 按钮数组
   * @private
   */
  private buttons: Array<any> = [];

  /**
   * 数据
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: { title: "", buttons: [] } })
  private dataSource: any;

  // 文字持续输入
  private onKeepTyping() {
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  /**
   * 文字输入完成
   * @private
   */
  private onCompleteTxt() {
    // 滚动到最底部
    this.$emit("scollAnimation");
    Fun.addTimeData(this.buttons, this.dataSource.buttons, this.onKeepTyping);
  }

  /**
   * 执行方法
   * @private
   */
  private onToDo({ callFunc, callFuncData }) {
    this.$emit("toDo", { callFunc, callFuncData });
  }
}
