
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/ai/components/vueTypedJs";
import { Fun } from "src/views/ai/utils/common";
import dayjs from "dayjs";
import { Toast } from "vant";
import AiScheduling from "src/views/ai/components/common/scheduling";

@Component({
  components: {
    VueTypedJs,
    AiScheduling,
  },
})
export default class SelectHospital extends Vue {
  /**
   * 选中下标
   * @private
   * @returns
   */
  private activeIndex: number = null;

  /**
   * 筛选数据
   * @private
   * @returns
   */
  private filter: any = {
    packageId: null,
    hospitalId: null,
    selectDate: "",
    physicalTimePeriod: "",
  };

  /**
   * 是否显示日期
   * @private
   * @returns
   */
  private showDate: boolean = false;

  /**
   * 显示日期弹框
   * @private
   * @returns void
   */
  private showDateModal(id: any, date: string = "", index: any): void {
    this.activeIndex = index;
    this.filter.hospitalId = id;
    this.filter.packageId = this.dataSource.packageId;
    this.filter.selectDate = date ? dayjs(date).format("YYYY-MM-DD") : "";
    this.showDate = true;
  }

  /**
   * 数据源
   * @private
   */
  private dataList: Array<any> = [];

  /**
   * 数据
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: { title: "", data: [], packageId: null, hasOptionalItems: false, employerMemberId: null } })
  private dataSource: any;

  // 文字持续输入
  private onKeepTyping() {
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  /**
   * 文字输入完成
   * @private
   */
  private onCompleteTxt() {
    // 滚动到最底部
    this.$emit("scollAnimation");
    Fun.addTimeData(this.dataList, this.dataSource.data, this.onKeepTyping, 200);
  }

  /**
   * 确认日期
   * @private
   * @returns void
   */
  private onDateConfirm(date: any, period: any): void {
    if (!date) {
      Toast("必须选择一个日期");
      return;
    }
    this.filter.selectDate = dayjs(date).format("YYYY-MM-DD");
    this.filter.physicalTimePeriod = period;
    this.showDate = false;
    this.onNext();
  }

  /**
   * 下一步
   * @private
   * @returns void
   */
  private async onNext(): Promise<void> {
    try {
      // 套餐有加项目
      if (this.dataSource.hasOptionalItems) {
        // 加项页面
        this.$router.push({
          name: "ai-package-optional",
          query: {
            id: this.filter.packageId,
            date: this.filter.selectDate,
            physicalTimePeriod: this.filter.physicalTimePeriod,
            hospitalId: this.filter.hospitalId,
            employerMemberId: this.dataSource.employerMemberId,
          },
        });
      } else {
        // 直接下单
        this.$emit("toDo", {
          callFunc: "ToBookOrder",
          callFuncData: {
            employerMemberId: this.dataSource.employerMemberId,
            packageId: this.filter.packageId,
            hospitalId: this.filter.hospitalId,
            physicalTime: this.filter.selectDate,
            physicalTimePeriod: this.filter.physicalTimePeriod,
            hasOptionalItems: this.dataSource.hasOptionalItems,
          },
        });
      }
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 查看更多服务网点
   * @private
   * @returns void
   */
  private onMoreHospital() {
    this.$router.push({
      name: "ai-package",
      query: {
        id: this.dataSource.packageId,
        employerMemberId: this.dataSource.employerMemberId,
        hasOptionalItems: this.dataSource.hasOptionalItems + "",
        pageType: "1",
      },
    });
  }
}
