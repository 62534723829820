
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/ai/components/vueTypedJs";
import { UserService, WechatService } from "src/services";
import { Fun } from "src/views/ai/utils/common";
import { tools } from "src/utils";

@Component({
  components: {
    VueTypedJs,
  },
})
export default class WxMiniProgram extends Vue {
  /**
   * 数据
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: { title: "", data: [], button: {} } })
  private dataSource: any;

  // 按钮集合
  private buttons: Array<any> = [];

  /**
   * 文字输入完成
   * @private
   */
  private onCompleteTxt() {
    // 滚动到最底部
    this.$emit("scollAnimation");
    Fun.addTimeData(this.buttons, [{ buttonText: "报告解读入口", id: new Date().getTime() }], this.onKeepTyping, 100, this.bindBtn);
  }

  // 文字持续输入
  private onKeepTyping() {
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get userInfo(): any {
    let userInfoStorge = localStorage.getItem("userInfo");

    if (userInfoStorge) {
      return JSON.parse(userInfoStorge);
    }

    return {};
  }

  /**
   * 跳转报告解读
   * @private
   * @returns void
   */
  private async initWx(id, result) {
    // 报告解读 -  拿到最新一条未解读数据
    try {
      let { content: resultData } = await UserService.instance.queryNotSumbitAnalyzeReport();
      let reportParams = {
        appid: "gh_387fc8fa08dd",
        eleId: id, // 元素id
        url: `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/list`, // 跳转小程序的页面路径
        content: this.reportContent, // 自定义的html内容
      };
      if (resultData && resultData.data && resultData.data.id) {
        reportParams.url = `/pages/health-records/index?k=${result.data.key}&uId=${this.userInfo.genUserId}&i=wx41259acafe15eaeb&u=/pages/report/decode?id=${resultData.data.id}`;
      }
      this.wx_launch(reportParams);
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 跳转小程序dom节点内容
   * @private
   * @returns string
   */
  private reportContent: string = `
        <button class="test-btn"></button>
        <style>
            .test-btn{
                width: 100%;
                height: 50px;
                background: transparent;
                border: none;
                color:rgba(255,255,255,0);
            }
            .test-btn:focus{outline: 0}
        </style>
    `;

  /**
   * 跳转小程序封装
   * @protected
   * @returns void
   */
  private async wx_launch(info): Promise<void> {
    if (!(await tools.isWechat())) {
      return;
    }
    var btn = document.getElementById(info.eleId); //获取元素
    if (!btn) {
      return;
    }
    let script = document.createElement("script"); // 创建script内容插槽 避免template标签冲突
    script.type = "text/wxtag-template"; // 使用script插槽 必须定义这个type
    script.text = info.content; // 自定义的html字符串内容
    let html = `<wx-open-launch-weapp style="width:100%; height:100%;display:block;overflow: hidden;position: absolute;top: 0;left: 0;z-index:999;" username="${info.appid}" path="${info.url}">${script.outerHTML}</wx-open-launch-weapp>`;
    btn.innerHTML = html + btn.innerHTML; // html字符串赋值
    // 点击按钮 正常跳转触发
    btn.addEventListener("launch", function (e) {
      console.log("success");
    });
    // 点击跳转 抛出异常
    btn.addEventListener("error", function (e) {
      console.log("fail", e);
      alert(`跳转异常 - ${JSON.stringify(e)}`);
    });
  }

  /**
   * 微信跳转
   * @private
   * @returns void
   */
  private async bindBtn() {
    try {
      let { content: result } = await UserService.instance.jump2Personal();
      if (result && result.data) {
        for (let i = 0; i < this.buttons.length; i++) {
          // 初始化微信dom
          this.initWx(this.buttons[i].id, result);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * 组件创建钩子
   * @private
   * @returns string
   */
  private created() {
    this.init();
  }

  /**
   * 初始化jssdk
   * @private
   * @returns Promise<any>
   */
  private async init(): Promise<any> {
    if (await tools.isWechat()) {
      let url = location.href;
      let { content: result } = await WechatService.instance.getWechatJSSDK(url);
      if (result.data) {
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.data.appId, // 必填，公众号的唯一标识
          timestamp: result.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
          signature: result.data.signature, // 必填，签名
          jsApiList: ["openLocation"],
          openTagList: ["wx-open-launch-weapp"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {});
      }
    }
  }
}
