
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/ai/components/vueTypedJs";
import { Fun } from "src/views/ai/utils/common";
import eventBus from "../../utils/bus";

@Component({
  components: {
    VueTypedJs,
  },
})
export default class TitleForm extends Vue {
  /**
   * 按钮是否已点击
   * @private
   */
  private isBtnClick: boolean = false;

  /**
   * 按钮数据
   * @private
   */
  private button: any = null;

  /**
   * 数据源
   * @private
   */
  private dataList: Array<any> = [];

  /**
   * 数据
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: { title: "", data: [], button: {} } })
  private dataSource: any;

  /**
   * 数据
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: { index: null, data: { isBtnClick: false } } })
  private judgeData: any;

  /**
   * 监听inputValue
   * @protected
   * @param {any} - to 当前路由实例。
   * @returns {void}
   */
  @Watch("judgeData", { deep: true, immediate: true })
  protected changeInputValue(newValue: any, oldValue: any): void {
    if (newValue && newValue.data && newValue.index != null) {
      this.isBtnClick = newValue.data.isBtnClick;
    }
  }

  // 文字持续输入
  private onKeepTyping() {
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  /**
   * 文字输入完成
   * @private
   */
  private onCompleteTxt() {
    // 滚动到最底部
    this.$emit("scollAnimation");
    Fun.addTimeData(this.dataList, this.dataSource.data, this.onKeepTyping, 100, this.onAppend);
  }

  /**
   * append  按钮
   * @private
   */
  private onAppend() {
    this.button = this.dataSource.button;
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  /**
   * 执行方法
   * @private
   */
  private onToDo({ callFunc, callFuncData }) {
    this.$emit("toDo", { callFunc, callFuncData });
    this.isBtnClick = true;
    eventBus.$emit("judgeData", { index: this.judgeData.index, data: { isBtnClick: this.isBtnClick } });
  }
}
