
import { Component, Vue, Watch } from "vue-property-decorator";

// 组件 begin
import OrgList from "./org-list.vue";
import Detail from "./detail.vue";
// 组件 end

@Component({
  components: {
    OrgList,
    Detail,
  },
})
export default class Package extends Vue {
  /**
   *  下标
   * @private
   */
  private activeIndex: number = 0;

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    if (this.$route.query && this.$route.query.pageType) {
      this.activeIndex = +this.$route.query.pageType;
    }
  }
}
