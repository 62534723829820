
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import VueTypedJs from "src/views/ai/components/vueTypedJs";
import { Fun } from "src/views/ai/utils/common";

@Component({
  components: {
    VueTypedJs,
  },
})
export default class NotifyMessage extends Vue {
  /**
   * 数据源
   * @private
   */
  private dataList: Array<any> = [];

  /**
   * 数据
   * @private
   * @returns string
   */
  @Prop({ type: Object, default: { title: "", data: [] } })
  private dataSource: any;

  // 文字持续输入
  private onKeepTyping() {
    // 滚动到最底部
    this.$emit("scollAnimation");
  }

  /**
   * 文字输入完成
   * @private
   */
  private onCompleteTxt() {
    // 滚动到最底部
    this.$emit("scollAnimation");
    Fun.addTimeData(this.dataList, this.dataSource.data, this.onKeepTyping);
  }
}
