
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component
export default class Login extends Vue {
  /**
   * 是否显示
   * @private
   * @returns string
   */
  @Prop({ type: Boolean, default: false })
  private show: boolean;
  private flags: boolean;
  private touchToDomTop: any;
  private domToTop: any;
  private maxH: any;

  /**
   * 监听弹框变化
   * @private
   * @returns void
   */
  @Watch("show", { immediate: true })
  private onShowChange(value: any): void {
    if (value) {
      this.$nextTick(() => {
        this.menu.DragEl = document.getElementById("Drag");
        this.menu.totalHeight = window.innerHeight;
        this.menu.totalWidth = window.innerWidth;
      });
    }
  }

  /**
   *  菜单
   * @private
   */
  private menu: any = {
    showItem: true,
  };

  private onSend(message: string) {
    this.menu.showItem = true;
    this.$emit("onSend", message);
  }

  private down(event) {
    let container: any = document.querySelector("#float-container");
    this.flags = true;
    let touch;
    if (event.touches) {
      touch = event.touches[0];
    } else {
      touch = event;
    }
    // 能移动到的最大高度
    this.maxH = document.body.clientHeight - container.offsetHeight;
    // 点击位置距离浮块顶部的距离
    this.touchToDomTop = touch.clientY - container.offsetTop;
  }

  private move(event) {
    event.preventDefault();
    let container: any = document.querySelector("#float-container");
    if (this.flags) {
      let touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      // 当前浮块顶部距离可视区top的距离
      this.domToTop = touch.clientY - this.touchToDomTop;
      if (this.domToTop < 0) {
        this.domToTop = 0;
      } else if (this.domToTop >= this.maxH) {
        this.domToTop = this.maxH;
      }
      if (window.innerHeight - 300 > this.domToTop) {
        container.style.top = this.domToTop + "px";
      } else {
        container.style.top = window.innerHeight - 300 + "px";
      }
      document.addEventListener(
        "touchmove",
        function () {
          // event.preventDefault()
        },
        false
      );
    }
  }

  //鼠标释放
  private end() {
    this.flags = false;
  }
}
